<template>
    <modal class="dayEndModel" :isShow="isShow">
        <div class="header">日结操作</div>
        <div class="modal-body flex-box">
            <div class="left-box">
                <div class="tr-li">
                    <div class="td-5">
                        <div class="lable-txt">营业日期:</div>
                        <div class="from-input">
                            <el-date-picker class="from-date"
                                :clearable="false"
                                :editable="false"
                                v-model="businessHours"
                                type="date"
                                placeholder=""
                                readonly
                                :default-value="new Date()">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="td-5">
                        <div class="lable-txt">结班人:</div>
                        <div class="from-input">
                            <input type="text" v-model="shiftEndName" readonly />
                        </div>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">营业门店:</div>
                    <div class="from-input">
                        <input type="text" v-model="department_Name" readonly />
                    </div>
                </div>
                <div class="bill-box">
                    <div class="title">
                        未结账单据
                        <el-checkbox v-model="clearSoldChecked" v-show="SoldShow">日结后将已沽清菜品恢复可售卖状态</el-checkbox>
                    </div>
                    <div class="table-box grey-table" v-table-el-height="'tableEl'" style="height:350px;overflow-y:auto;">
                        <el-table ref="tableEl" border  :data="tableData"  style="width: 100%;"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                            <el-table-column  label="选择" width="44">
                                <template #default="scope">
                                    <span class="iconfont" :class="scope.row.checked?'icon-iconoption1':'icon-iconoption'" @click="UnsettledClick(scope)"></span>
                                </template>
                            </el-table-column>
                            <el-table-column label="开单号" min-width="146">
                                <template #default="scope">
                                    <span @click="UnsettledClick(scope)">{{scope.row.Eat_ID}}</span>
                                </template>
                            </el-table-column> 
                            <el-table-column label="渠道" min-width="43" >
                                <template #default="scope">
                                    <span @click="UnsettledClick(scope)">{{scope.row.CHANNEL_NAME}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="开台人" min-width="50"> 
                                <template #default="scope">
                                    <span @click="UnsettledClick(scope)">{{scope.row.Eat_Czy}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="桌号" width="44">
                                <template #default="scope">
                                    <span @click="UnsettledClick(scope)">{{scope.row.Eat_DeskName}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="开台时间" width="80">
                                <template #default="scope">
                                    <span @click="UnsettledClick(scope)">{{ new Date(scope.row.Eat_BeginTime).Format("yyyy-MM-dd")}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="订单金额" width="55">
                                <template #default="scope">
                                    <span @click="UnsettledClick(scope)">{{scope.row.Eat_SaleMoney}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="ResultDesc">
                    {{ResultDesc}}
                </div>
            </div>
            <div class="right-box">
                <div class="title">日结单预览</div>
                <div class="preview">
                    <div class="empty-box" v-show="printBodies" ref="empty">
                        <div v-html="Print_Html" style="display: inline-block;"></div>
                    </div>
                    <div class="empty-boxNo verticalCenter" v-show="printBodies==''">
                        <div class="content-box" style="text-align: center;">
                            <svg class="iconfont" aria-hidden="true">
                                <use xlink:href="#icon-kongdingdan"></use>
                            </svg>
                            <div>无待日结订单</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">返回</button>
            <button class="btn " :class="{selected:unsettledType==1}" @click="voidOrderClick()">作废单据</button>
            <button class="btn selected" @click="confirm()">日结</button>
        </div>
        <!-- 作废账单操作 -->
        <modal-load :isShow="voidShow">
            <void-Reason-Model :isShow="voidShow" v-on:closeModel="voidShow=false" @voidconfirm="voidconfirm"></void-Reason-Model>
        </modal-load>
    </modal>
</template>

<script>
import voidReasonModel from './voidReasonModel/voidReasonModel.vue'
import { newGuid,printView } from '/src/common/index.js'
import {cacheData} from '../../../../services'

/**全局初始化数据 */
let _data={
    DailyEndCheckOutBills:false, /**如存在未结账完毕的单据,则不允许日结 */
    SoldOutModelAfterDayFinished:0, /**日结时，沽清菜品处理方式，0:不做任何处理,1:日结时,让用户选择是否取消沽清菜品,2:日结时,自动取消沽清菜品 */
};
// 日结操作
export default {
    name:'dayEndModel',
    components:{
        voidReasonModel
    },
    props:{
        isShow:Boolean,
    },
    data(){
        let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
            _data.DailyEndCheckOutBills = globalSysSetting.RunDailyEndWithNoUnCheckOutBills;
            _data.SoldOutModelAfterDayFinished = globalSysSetting.SoldOutModelAfterDayFinished;
        }
        return{
            /**营业日期 */
            businessHours:new Date(),
            /**结账单信息 */
            printBodies:'',
            /**获取用户*/
            userInfo:'',
            /**结班人 */
            shiftEndName:'',
            /**营业门店 */
            department_Name:'',
            tableData:[],
            unsettledType:0,
            voidShow:false,
            InvalidOrdersData:[],
            /**作废原因 */
            refundReasonName:'',
            clearSoldChecked:false,
            SoldShow:false,
            /**提示 */
            ResultDesc:'',
            isDayEndBtn:0, /**是否触发日结按钮 */
        }
    },
    computed:{
        /** 打印数据展示 html **/
        Print_Html(){
            return printView(this.printBodies);
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.init();
        });
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        }
    },
    methods:{
        /**初始化数据 */
        init(){
            setTimeout(() => {
                this.$refs.empty.scrollTop=0;
            },100);
            this.userInfo= this.$auth.getUserInfo();
            this.tableData=[]
            this.unsettledType=0
            this.InvalidOrdersData=[]
            this.printBodies=''
            this.isDayEndBtn=0
            if(this.userInfo){//营业日期
                this.businessHours=this.userInfo.Rpt_Date;
            }
            this.shiftEndName = this.userInfo.Login_Name
            this.department_Name = this.userInfo.Department_Name
            if( _data.SoldOutModelAfterDayFinished==1){
                this.SoldShow=true
            }
            this.DailySettlement(1)
        },
        
        hide(){
            this.$emit("closeModel");
        },
        
        confirm(){
            if(_data.DailyEndCheckOutBills){
                if(this.tableData.length>0){
                    this.$message.warning('存在未结单数据!');
                }else{
                    this.DailySettlement(2)
                }
            }else{
                 this.DailySettlement(2)
            }
        },
         /**获取日结数据 */
        DailySettlement(type){
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                SettlementOperation:type, //1.日结预览,2.确认日结
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Operate_Pos:this.userInfo?.Site_Name, //操作站点
                IsClearSoldOut:this.clearSoldChecked
            }
            if(type==2 && this.SoldShow == true){
                let cleaTitle=''
                if(this.clearSoldChecked){
                    cleaTitle='您确认将'+this.businessHours+'日结吗,并且恢复已沽清菜品为可售卖状态?'
                }else{
                    cleaTitle='您确认将'+this.businessHours+'日结吗,并且不恢复已沽清菜品为可售卖状态?'
                }
                this.$confirm(cleaTitle, '提示', {
                    confirmButtonText: '确定日结',
                    cancelButtonText: '取消',
                    type: 'warning'})
                .then(() => {
                    this.DailySett(type,param)
                }).catch(()=>{});
            }else{
                this.DailySett(type,param)
            }
        },
        DailySett(type,param){
            if(this.isDayEndBtn==0){
                if(type==2){
                    this.isDayEndBtn=1
                }
                const loading = this.$loading({
                    text: "获取日结信息中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                this.$httpAES.requestConfig({
                    timeout:2*60*1000//设置2分钟 超时时间
                }).post("Bestech.CloudPos.DailySettlement",param).then((data)=>{
                    loading.close();
                    if(data.ResponseHeader.ResultCode==0){
                        this.isDayEndBtn=0
                        if(type==2){
                            this.ResultDesc=''
                            setTimeout(()=>{
                                this.$webBrowser.posPrint(data.ResponseBody);
                            },100);
                            this.$alert(this.businessHours+'日结成功!', '提示', {
                                confirmButtonText: '确定',type:'warning',
                                callback: action => {
                                    this.$auth.logout();
                                }
                            })
                        }else{
                            this.ResultDesc=data.ResponseHeader.ResultDesc
                            if(data.ResponseBody){
                                this.printBodies = data.ResponseBody[0].PrintBodies;
                                /**获取渠道 */
                                this.$cacheData.OrderChannel().then((d)=>{
                                    this.orderChannel = d;
                                    this.orderChannel?.map(order=>{
                                        order.OrderChannelList.map(item=>{
                                            data.ResponseBody[0].UnCheckOutOrders.map(tab=>{
                                                if(tab.CHANNEL_ID==item.OrderChannel_AutoID){
                                                    tab = Object.assign(tab, {CHANNEL_NAME:item.OrderChannel_Name})
                                                    this.tableData.push(tab)
                                                }
                                            })
                                        })
                                    })
                                }).catch(()=>{

                                })
                            }
                        }
                    }else if(data.ResponseHeader.ResultCode==8001){
                        this.isDayEndBtn=0
                        this.$message.warning(data.ResponseHeader.ResultDesc);
                    }else{
                        this.isDayEndBtn=0
                        this.$message.error("日结信息获取失败:"+data.ResponseHeader.ResultDesc);
                    }
                }).catch((e)=>{
                    loading.close();
                    this.memberInfo=null,
                    this.$message.error('日结信息读取失败:'+e);
                })
            }
        },
        UnsettledClick(scope){
            scope.row.checked=!scope.row.checked
            if(scope.row.checked==true){
                let order={
                    Order_ID:scope.row.Eat_AutoID,
                    Timestamps:scope.row.Timestamps
                }
                this.InvalidOrdersData.push(order)
            }else{
                this.InvalidOrdersData.map((item,index)=>{
                    if(item.Order_ID==scope.row.Eat_AutoID){
                        this.InvalidOrdersData.splice(index,1)
                    }
                })
            }
            if(this.InvalidOrdersData.length==0){
                this.unsettledType=0
            }else{
                this.unsettledType=1
            }
        },
        voidOrderClick(){
            if(this.unsettledType==1){
                this.voidShow = true
            }else{
                 this.$message.warning('请先选择需要作废的订单');
            }
        },
        /**作废原因返回 */
        voidconfirm(reasonInfo){
            this.voidShow = false
            this.refundReasonName = reasonInfo.RefundReason_Name
            const loading = this.$loading({
                text: "作废订单信息中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                InvalidOrders:this.InvalidOrdersData, //作废订单列表
                Invalid_Reason:reasonInfo.RefundReason_Name, //作废原因
                Operate_User:this.userInfo?.Login_Name, //操作员
                Operate_PosName:this.userInfo?.Site_Name //操作站点
            }
            console.log('param:'+JSON.stringify(param))
            this.$httpAES.post("Bestech.CloudPos.InvalidOrders",param).then((data)=>{
                console.log('返回内容:'+JSON.stringify(data))
                loading.close();
                if(data.ResponseHeader.ResultCode==0){
                    this.$message.success("作废数据成功");
                    this.InvalidOrdersData.map(data=>{
                        this.tableData.map((item,index)=>{
                            if(item.Eat_AutoID==data.Order_ID){
                                this.tableData.splice(index,1)
                            }
                            
                        })
                    })
                    this.InvalidOrdersData=[]
                    return
                }else{
                    this.$message.error("作废订单失败:"+data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('作废订单失败:'+e);
            })
        },
    }
}
</script>

<style lang="scss">
@import './dayEndModel.scss'
</style>